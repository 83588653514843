var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container mx-auto px-2 md:px-0" }, [
    _c("div", { staticClass: "w-full px-4 inline-block" }, [
      _c("h1", { staticClass: "text-2xl font-normal pt-2" }, [
        _vm._v(_vm._s(_vm.$t("pages.faq.title"))),
      ]),
    ]),
    _c("div", { staticClass: "w-full px-4 text-justify mb-8" }, [
      _vm.displayType === "ch"
        ? _c("span", { staticClass: "text-sm" }, [
            _vm._v(
              "お問合せでよくある質問を網羅しています。お客様の大体の疑問はこちらで解決するかと思いますので、ぜひご一読ください。"
            ),
          ])
        : _vm._e(),
    ]),
    _c(
      "div",
      {
        staticClass:
          "w-full px-4 grid grid-cols-1 grid-flow-row auto-rows-auto md:grid-cols-2 xl:gap-12 lg:gap-6 gap-3",
      },
      _vm._l(_vm.genres, function (genre, index) {
        return _c("div", { key: "genre-" + index, staticClass: "col-span-1" }, [
          _c("p", { staticClass: "pb-2" }, [
            _vm._v(_vm._s(genre.name)),
            _vm.displayType === "ch"
              ? _c("span", [_vm._v("に関して")])
              : _vm._e(),
          ]),
          _c(
            "div",
            { staticClass: "ml-4" },
            [
              _vm.faqs[index] && _vm.faqs[index].length
                ? _vm._l(_vm.faqs[index], function (item, key) {
                    return _c(
                      "FlAccordion",
                      {
                        key: "faq-" + key,
                        staticClass: "mb-2",
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c("span", [_vm._v(_vm._s(item.question))]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        [
                          _c("div", {
                            staticClass: "text-justify",
                            domProps: { innerHTML: _vm._s(item.answer) },
                          }),
                          item.reference_file && item.file_explanation
                            ? _c("div", [
                                _c("p", [
                                  _c(
                                    "a",
                                    {
                                      staticClass: "underline",
                                      attrs: {
                                        href: item.reference_file.src,
                                        download: item.reference_file.fileName,
                                        target: "_blank",
                                      },
                                    },
                                    [_vm._v(_vm._s(item.file_explanation))]
                                  ),
                                ]),
                              ])
                            : _vm._e(),
                        ],
                      ],
                      2
                    )
                  })
                : _vm._e(),
            ],
            2
          ),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }